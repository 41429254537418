import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { lastValueFrom } from 'rxjs';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { NavbarStyleTwoComponent } from 'src/app/components/common/navbar-style-two/navbar-style-two.component';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { PaginationFilterInput } from 'src/app/models/request-models/pagination-review-response';
import { SalesTransactions } from 'src/app/models/sales-transactions';
import { SetPayouts, SetTransaction } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-dashboard-sales-transactions',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    NavbarStyleTwoComponent,
    DashboardSidemenuComponent,
    DashboardNavbarComponent
  ],
  templateUrl: './dashboard-sales-transactions.component.html',
  styleUrl: './dashboard-sales-transactions.component.scss'
})
export class DashboardSalesTransactionsComponent   extends SnappstayBaseComponent
implements OnInit, OnDestroy
{
  totalRecords = 0;
  pageSize = 10;
  currentPage = 1;
  sortField = 'fullName';
  sortDirection = 'asc';
  filter = '';
  showContextMenu : boolean = false;
  salesTransactions : SalesTransactions[] =[];
  constructor(inject: Injector) {
    super(inject);
  }
  ngOnInit(): void {
    this.currentPage = 1;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
      search: this.filter,
    };
    this.getAllSalesTransactions(request);
    this.SetAllUser();
  }

  SetAllUser() {
    const data = this.store
    .select(SnappstayState.GetTransaction)
    .subscribe((value) => {
      if (value && value.length > 0) {
        this.salesTransactions  =[];
        this.salesTransactions = value;
      } 
      
    });
  

    this.subscriptions.push(data);
  }
  applyFilter(event: Event) {
    this.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.currentPage = 1;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
      search: this.filter,
    };
    this.getAllSalesTransactions(request);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: 'Id',
    };
    this.getAllSalesTransactions(request);
  }

  onSortChange(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    const request: PaginationFilterInput = {
      pageNumber: this.currentPage,
      pageSize: 10,
      sortColumn: this.sortField,
      sortOrder: this.sortDirection,
    };
    this.getAllSalesTransactions(request);
  }

  async getAllSalesTransactions(request: PaginationFilterInput) {
    try {
      const salesTransactions$ = this.walletApiService.getAllSalesTransactions(request);
      const salesTransactions = await lastValueFrom(salesTransactions$, {
        defaultValue: undefined,
      });
      if (salesTransactions) {
        this.store.dispatch(new SetTransaction(salesTransactions));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetTransaction(undefined));
    } finally {
    }
  }
  onContextMenu(action: string) {
    // Handle context menu actions like 'view', 'edit', 'delete'
  }

  ngOnDestroy(): void {}
}
