import { Component, OnInit } from '@angular/core';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from '../../common/footer-style-one/footer-style-one.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-blog-details',
    templateUrl: './blog-details.component.html',
    styleUrls: ['./blog-details.component.scss'],
    standalone: true,
    imports:[
        CommonModule,
        NavbarStyleOneComponent,
        FooterStyleOneComponent
    ]
})
export class BlogDetailsComponent implements OnInit {

        

    ngOnInit(): void {
    }

    pageTitleContent = [
        {
            title: 'Blog Details',
            backgroundImage: 'assets/img/page-title/page-title1.jpg'
        }
    ]

}