<div class="option-item">
    <div class="dropdown profile-nav-item">
        <button type="button" class="dropdown-toggle bg-transparent border-0" (click)="toggleClass2()">
            <div class="menu-profile">
                <img *ngIf="(userProfile$ | async)?.profileImageUrl; else defaultImage" 
                [src]="environmentVar.blobUrl + (userProfile$ | async).profileImageUrl"
                class="rounded-circle" 
                alt="User profile image">
           
           <ng-template #defaultImage>
               <img src="assets/img/download.png" 
                    class="rounded-circle" 
                    alt="Default profile image">
           </ng-template>
           
            </div>
        </button>
        <div class="dropdown-menu" [class.active]="classApplied2">
            <div class="dropdown-header d-flex flex-column align-items-center" *ngIf="((Authorize$ | async))">
                <div class="info text-center">
                    <span class="name">{{(userProfile$ | async).fullName}}</span>
                    <p class="mb-3 email">{{(userProfile$ | async).email}}</p>
                </div>
            </div>
            <div class="dropdown-body">
                <ul class="profile-nav p-0 pt-3">
                    <li class="nav-item" *ngIf="((Authorize$ | async) && role != 'Guest')" (click)="moveToDashboard()">
                        <a class="nav-link"><i class='bx bx-grid-alt'></i> <span>Dashboard</span></a>
                    </li>
                    <li class="nav-item" (click)="moveToHomeTwo($event)" *ngIf="((Authorize$ | async) && role != 'Guest')">
                        <a class="nav-link"><i class='bx bx-home'></i> <span>Home</span></a>
                    </li>
                    <li class="nav-item" (click)="moveToUpdateProfile()">
                        <a class="nav-link"><i class='bx bx-user-circle'></i> <span>Update profile</span></a>
                    </li>
                    <li class="nav-item" (click)="moveToChangePassword()">
                        <a class="nav-link"><i class='bx bx-lock'></i> <span>Change password</span></a>
                    </li>
                    <li class="nav-item" *ngIf="((Authorize$ | async))" (click)="moveToMessages()">
                        <a class="nav-link"><i class='bx bx-envelope'></i> <span>Messages</span></a>
                    </li>
                    <li class="nav-item" *ngIf="((Authorize$ | async) && role != 'Administrator')" (click)="moveToBookings()">
                        <a class="nav-link"><i class='bx bx-calendar'></i> <span>Bookings</span></a>
                    </li>
                    <li class="nav-item" *ngIf="((Authorize$ | async) && role == 'Host')" (click)="moveToAddListing()">
                        <a class="nav-link"><i class='bx bx-plus-circle'></i> <span>Add Listings</span></a>
                    </li>
                </ul>
            </div>
            <div class="dropdown-footer" *ngIf="((Authorize$ | async))">
                <ul class="profile-nav">
                    <li class="nav-item" (click)="logOut()" style="cursor: pointer;">
                        <a routerLink="/" class="nav-link"><i class='bx bx-log-out-circle'></i> <span>Logout</span></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
