<app-navbar-style-two></app-navbar-style-two>
<style>
    /* Apply scrolling only to the content of col-lg-5 */
    .scrollable-content {
        height: calc(100vh - 150px);
        /* Adjust based on your header/footer height */
        overflow-y: auto;
        padding-right: 10px;
        /* Optional: To avoid scrollbar overlapping */
    }

    /* Ensure smooth scrolling */
    .scrollable-content::-webkit-scrollbar {
        width: 8px;
    }

    .scrollable-content::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }

    .scrollable-content::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }
</style>
<!-- Start Listings Area -->
<section class="listings-area ptb-100" style="padding-top: 0 !important;padding-bottom: 0 !important;overflow: hidden;">
    <div class="container-fluid">
        <div class="row">
            <div class="listings-grid-sorting row align-items-center">
                <div class="col-lg-5 col-md-6 result-count">
                    <!-- <p>We found <span class="count">{{baseGridResponse.totalRecords}}</span> listings available for you</p> -->
                </div>
                <div class="col-lg-7 col-md-6 ordering">
                    <div class="d-flex justify-content-end">
                        <div class="select-box me-2 d-flex align-items-center" *ngIf="!IsLuxury">
                            <label>Price</label>
                            <!-- <ngx-select-dropdown (change)="onPriceRangeChange($event)" name="price-range-select"
                                tabindex="0" [multiple]="false" [(ngModel)]="selectedPriceRange"
                                [config]="priceConfig" [options]="priceRanges">
                            </ngx-select-dropdown> -->
                            <div class="form-group" style="text-align: left;">
                                <ng-select [items]="priceRanges" bindLabel="name" bindValue="name" [multiple]="false"
                                    placeholder="Select price range" [(ngModel)]="selectedPriceRange"
                                    (change)="onPriceRangeChange($event)">
                                </ng-select>
                            </div>
                        </div>
                        <div class="select-box me-2 d-flex align-items-center"> <!-- Add margin to space them out -->
                            <label>Categories</label>
                            <!-- <ngx-select-dropdown (searchChange)="searchChange($event)" name="ordering-select"
                                (change)="onPropertyTypeChange($event)" tabindex="0" [multiple]="true"
                                [(ngModel)]="singleSelect" [config]="config"
                                [options]="propertyType"></ngx-select-dropdown> -->
                            <div class="form-group" style="text-align: left;">
                                <ng-select [items]="propertyType" bindLabel="name" bindValue="id" [multiple]="true"
                                    placeholder="Select categries" [(ngModel)]="singleSelect"
                                    (change)="onPropertyTypeChange($event)">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5 col-md-12 px-0">
                <!-- Set the height to fill the available space on screen -->
                <cdk-virtual-scroll-viewport *ngIf="singleListingsBox?.length > 0; else emptyState" [itemSize]="250"
                    style="height: 78vh; overflow-y: auto;" class="viewport">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 px-3" *cdkVirtualFor="let Content of singleListingsBox">
                            <div class="single-listings-item mb-3">
                                <div class="row m-0">
                                    <div class="col-lg-4 col-md-4 p-0" style="width: 200px;height: 150px;">
                                        <owl-carousel-o [options]="customOptions">
                                            <ng-template carouselSlide *ngFor="let Image of Content.mainImg;">
                                                <div class="single-image" style="height: 156px;">
                                                    <img
                                                      [src]="Image.img" alt="image"
                                                      class="img-responsive"
                                                      style="
                                                      height: 156px;
                                                      border-top-left-radius: 4px;
                                                      border-bottom-left-radius: 4px;"
                                                    >
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>

                                    <div class="col-lg-8 col-md-8 p-0">
                                        <div class="listings-content p-3">
                                            <h3 style="margin-bottom: 7px;font-size: 14px;font-weight: 500;cursor: pointer;"
                                                (click)="moveToSingleListing(Content.id)">
                                                {{Content.title}}
                                            </h3>
                                            <div class="d-flex align-items-center justify-content-between">
                                                <div class="rating" *ngIf="Content.rating != null">
                                                    <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                                    <span class="count"
                                                        *ngIf="Content.ratingCount > 0">({{Content.ratingCount}})</span>
                                                </div>
                                                <div class="price" style="font-weight: 500;">
                                                    ${{ Content.price | number:'1.0-2' }} per night
                                                </div>
                                            </div>
                                            <ul class="listings-meta" style="margin-bottom: 0px;">
                                                <li
                                                    style="margin-right: 15px;margin-bottom: 10px;font-weight: 400;font-size: 11px;">
                                                    <i class="flaticon-furniture-and-household"></i>
                                                    {{Content.category}}
                                                </li>
                                                <li
                                                    style="margin-right: 15px;margin-bottom: 10px;font-weight: 400;font-size: 11px;">
                                                    <i class="flaticon-pin"></i>{{Content.location}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>

                <!-- Empty State Template -->
                <ng-template #emptyState>
                    <div class="empty-state"
                        style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                        <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
                    </div>
                </ng-template>
            </div>

            <div class="col-lg-7 col-md-12 ps-0" style="height: 78vh;">
                <snappstay-map-component [isReadOnly]="isReadOnly" [markersData]="markers"
                    (mapClick)="onMapClick($event)" (searchOnZoom)="searchOnZoom($event)" [focus]="bounds">
                </snappstay-map-component>
            </div>
        </div>


        <!-- <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
                    <pagination-controls (pageChange)="onPageChange($event)" id="server"></pagination-controls>
                </div>
            </div>
        </div> -->
    </div>
</section>
<!-- End Listings Area -->

<!-- <app-footer-style-two></app-footer-style-two> -->
