<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="heading-container" style="margin-top: 10px;">
        <h2 style="font-weight: bold; color: #333;">Bookings</h2>
    </div>
    <!-- Start -->
    <section class="listing-section">
        <!-- Filter Input -->
        <div class="row mb-3">
            <div class="col">
                <input type="text" class="form-control" (keyup)="applyFilter($event)" placeholder="Filter by any field">
            </div>
        </div>

        <!-- Table with sorting and pagination -->
        <div *ngIf="bookingsListingsBox.length > 0; else noData" class="listing-section">
            <table class="listing-table">
                <thead>
                    <tr>
                        <th (click)="onSortChange('customerName')" class="heading">Customer Name</th>
                        <th (click)="onSortChange('customerNumber')" class="heading">Phone Number</th>
                        <th (click)="onSortChange('customerEmail')" class="heading">Email</th>
                        <th (click)="onSortChange('title')" class="heading">Booking Title</th>
                        <th (click)="onSortChange('bookingsStatus')" class="heading">Status</th>

                        <!-- Render additional columns based on detailHeaders array -->
                        <th *ngFor="let header of detailHeaders" class="heading">{{ header }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let booking of bookingsListingsBox | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalRecords }"
                        style="height: 50px; ">

                        <td class="name">
                            <div class="info">
                                <span>{{ booking.customerName }}</span>
                            </div>
                        </td>

                        <td>
                            <a href="tel:{{ booking.customerNumber }}">{{ booking.customerNumber }}</a>
                        </td>

                        <td>
                            <a href="mailto:{{ booking.customerEmail }}">{{ booking.customerEmail }}</a>
                        </td>

                        <td>{{ booking.title }}</td>

                        <!-- Status text color based on booking status -->
                        <td [ngStyle]="{ 
                                'color': booking.bookingsStatus === 'Confirmed' ? '#28a745' : 
                                          (booking.bookingsStatus === 'Pending' ? '#d2a65b' : '#333'),
                                'font-weight': 'bold',
                                'padding': '10px',
                                'text-align': 'center'
                            }">
                            <span class="bookings-status {{ booking.pendingApprovedCanceled }}">{{
                                booking.bookingsStatus }}</span>
                        </td>

                        <!-- Render each booking detail in its own column -->
                        <td>{{ booking.address }}</td>
                        <td>{{ booking.date }}</td>
                        <td>{{ booking.price }}</td>
                        <td>{{ booking.persons }}</td>

                        <!-- Payment text color based on payment status -->
                        <td [ngStyle]="{ 
                                'color': booking.payment === 'Confirmed' ? '#28a745' : 
                                          (booking.payment === 'Pending' ? '#d2a65b' : '#333'),
                                'font-weight': 'bold',
                                'padding': '10px',
                                'text-align': 'center'
                            }">
                            {{ booking.payment }}
                        </td>
                        <td class="action-column">
                            <button class="context-btn" (click)="toggleContextMenu(booking)" aria-haspopup="true"
                                aria-expanded="Content.showMenu">&#x22EE;</button>

                            <!-- Context Menu -->
                            <div *ngIf="booking.showMenu" class="context-menu">
                                <ul class="context-menu-list">
                                    <li (click)="moveToChat(booking.guestId)">Chat</li>
                                </ul>
                                <ul class="context-menu-list" *ngIf="booking.payment === 'Pending' && role == 'Guest' ">
                                    <li (click)="moveToCheckOut(booking.id)">Pay Now</li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </tbody>



            </table>
        </div>

        <!-- No Data Template -->
        <ng-template #noData>
            <table class="listing-table">
                <tbody>
                    <tr>
                        <td colspan="6" class="text-center">
                            <div class="empty-state"
                                style="display: flex; justify-content: center; align-items: center; min-height: 65vh; width: 100%;">
                                <img src="assets/images/no-data.png" alt="No Data" class="no-data-image">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>

        <!-- Pagination Controls -->
        <div class="pagination-area text-center">
            <pagination-controls id="server" itemsPerPage="pageSize" currentPage="currentPage" totalItems="totalRecords"
                (pageChange)="onPageChange($event)">
            </pagination-controls>
        </div>
    </section>


    <!-- End -->

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->