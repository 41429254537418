import { Component, OnInit } from '@angular/core';
import { RelatedProductsComponent } from './related-products/related-products.component';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { CommonModule } from '@angular/common';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';

@Component({
    selector: 'app-products-details',
    templateUrl: './products-details.component.html',
    styleUrls: ['./products-details.component.scss'],
    standalone: true,
    imports:[
        RelatedProductsComponent,
        FooterStyleTwoComponent,
        CommonModule,
        NavbarStyleOneComponent
    ]
})
export class ProductsDetailsComponent implements OnInit {

        

    ngOnInit(): void {}
    
    pageTitleContent = [
        {
            title: 'Products Details',
            backgroundImage: 'assets/img/page-title/page-title4.jpg'
        }
    ]

    // Tabs
    currentTab = 'tab1';
    switchTab(event: MouseEvent, tab: string) {
        event.preventDefault();
        this.currentTab = tab;
    }

}