import { CommonModule } from '@angular/common';
import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DashboardNavbarComponent } from '../../common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from '../../common/dashboard-sidemenu/dashboard-sidemenu.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { CopyrightsComponent } from '../dashboard/copyrights/copyrights.component';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { ChangePasswordRequest } from 'src/app/models/request-models/change-password.request';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { lastValueFrom } from 'rxjs';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrl: './update-password.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    NavbarStyleTwoComponent,
    DashboardSidemenuComponent,
    DashboardNavbarComponent,
    CopyrightsComponent,
    ReactiveFormsModule,
  ],
})
export class UpdatePasswordComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  changePasswordForm: FormGroup;
  isChangePasswordModelOpen : boolean = true;
  constructor(inject: Injector, private fb: FormBuilder) {
    super(inject);
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: [null, Validators.required],
        newPassword: [null, Validators.required],
        confirmPassword: [null, Validators.required],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  // Custom validator to check if newPassword and confirmPassword match
  passwordMatchValidator(form: FormGroup) {
    return form.get('newPassword')?.value === form.get('confirmPassword')?.value
      ? null
      : { passwordMismatch: true };
  }

  submitForm() {
    if (this.changePasswordForm.valid) {
      const formData = this.changePasswordForm.getRawValue();
      const request: ChangePasswordRequest = {
        confirmPassword: formData.confirmPassword,
        currentPassword: formData.currentPassword,
        newPassword: formData.newPassword,
        email: this.store.selectSnapshot(SnappstayState.GetUserProfile).email
      };
      this.changePassword(request).then(() => {
        this.moveToHomeTwo();
      });
    }
  }

  async changePassword(request: ChangePasswordRequest) {
    try {
      const changePassword$ = this.accountApiService.changePassword(request);
      const changePassword = await lastValueFrom(changePassword$, {
        defaultValue: undefined,
      });
      if (changePassword) {
        this.toastr.success('Password changed successfully', '', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
    }
  }
  closePopup(){
    this.isChangePasswordModelOpen = false;
    this.role == 'Guest' ? this.moveToHomeTwo(): this.moveToDashboard();
  }
  ngOnDestroy(): void {}
}
