import { Component, OnInit } from '@angular/core';
    
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { HowItWorksComponent } from '../../common/how-it-works/how-it-works.component';
import { FooterStyleOneComponent } from '../../common/footer-style-one/footer-style-one.component';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';

@Component({
    selector: 'app-how-it-works-page',
    templateUrl: './how-it-works-page.component.html',
    styleUrls: ['./how-it-works-page.component.scss'],
    standalone: true,
    imports:[   
        NavbarStyleTwoComponent,
        HowItWorksComponent,
        FooterStyleTwoComponent,
        CommonModule,
        RouterLinkWithHref,
        RouterLinkActive,
    ]
})
export class HowItWorksPageComponent implements OnInit {

        

    ngOnInit(): void {
    }

    pageTitleContent = [
        {
            title: 'How it works',
            backgroundImage: 'assets/img/page-title/page-title5.jpg'
        }
    ]

    timelineContent = [
        {
            number: '1',
            title: 'Submit Your Listing',
            description: 'Add your room details, photos, and description to attract potential guests.',
            buttonText: 'Submit Now',
            buttonLink: '#'
        },
        {
            number: '2',
            title: 'Select a Package',
            description: 'Choose from our free or premium listing options to maximize visibility.',
            buttonText: 'Choose Now',
            buttonLink: '#'
        },
        {
            number: '3',
            title: 'Login to Your Account',
            description: 'Access your dashboard to manage your listings and track bookings.',
            buttonText: 'Login Now',
            buttonLink: '#'
        },
        {
            number: '4',
            title: 'Publish Your Listing',
            description: 'Finalize your listing and make it live on our platform for guests to see.',
            buttonText: 'Publish Now',
            buttonLink: '#'
        },
        {
            number: '5',
            title: 'Admin Approval',
            description: 'Our team reviews and approves your listing for quality assurance.',
            buttonText: 'Browse Listings',
            buttonLink: '#'
        }
    ];
    

}