<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Blog Area -->
<section class="blog-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-md-6" *ngFor="let Content of singleBlogPost | paginate: { itemsPerPage: 8, currentPage: blogRightSidebar }">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/{{Content.link}}" class="d-block">
                                    <img [src]="Content.mainImg" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta d-flex align-items-center">
                                    <li>
                                        <div class="d-flex align-items-center">
                                            <img [src]="Content.authorImg" alt="image">
                                            <span>{{Content.authorName}}</span>
                                        </div>
                                    </li>
                                    <li><i class="flaticon-calendar"></i> {{Content.date}}</li>
                                </ul>
                                <h3><a routerLink="/{{Content.link}}">{{Content.title}}</a></h3>
                                <a routerLink="/{{Content.link}}" class="link-btn"><i class="flaticon-right-arrow"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <pagination-controls (pageChange)="blogRightSidebar = $event"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>

                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_vesax_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2024</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">The Data Surrounding Higher Education</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2024</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Conversion Rate the Sales Funnel Optimization</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2024</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Business Data is changing the world’s Energy</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-grid">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-grid">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-grid">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-grid">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-grid">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-grid">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-grid">Aike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-grid">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-grid">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>

                    <section class="widget widget_instagram">
                        <ul>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog9.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog8.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog7.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog6.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog5.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                            <li>
                                <div class="box">
                                    <img src="assets/img/blog/blog4.jpg" alt="image">
                                    <i class="bx bxl-instagram"></i>
                                    <a href="#" target="_blank" class="link-btn"></a>
                                </div>
                            </li>
                        </ul>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->

<app-footer-style-one></app-footer-style-one>