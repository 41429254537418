import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { GetPropertyRequest } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-author-profile',
  templateUrl: './author-profile.component.html',
  styleUrls: ['./author-profile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    NavbarStyleTwoComponent,
    FooterStyleTwoComponent,
    CarouselModule,
    RouterLinkWithHref,
    RouterLinkActive,
  ],
})
export class AuthorProfileComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  blogGrid: number = 1;
  singleListingsBox: any[] = [];
  hostId: number | undefined;
  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit(): void {
    this.routeParamSub();
  }

  private routeParamSub() {
    const user = this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.has('id')) {
        this.hostId = params.params.id;
        this.GetListingApiCalling();
        this.listingsSub();
      }
    });
    this.subscriptions.push(user);
  }

  private GetListingApiCalling() {
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      isLuxury: this.IsLuxury,
      hostId: this.hostId,
    };
    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }

  listingsSub() {
    const listingSub = this.listings$.subscribe((item) => {
      if (item) {
        this.singleListingsBox = [];
        item.result.forEach((property: ListingResponse) => {
          const images: any[] = [];
          if (property.propertyPicture) {
            property.propertyPicture.forEach((image) => {
              images.push({
                img: `${this.environmentVar.blobUrl}` + image.path,
              });
            });
          }
          const newList = {
            id: property.id,
            mainImg: images,
            categoryLink: 'single-listings',
            category: property.propertyCategory,
            bookmarkLink: 'single-listings',
            location: `${property.city}-${property.country}`,
            title: property.title,
            price: property.pricePerNight,
            detailsLink: 'single-listings',
            authorImg: '',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            authorName: '',
            rating: [],
            ratingCount:
              property.numberOfReviews != 0 ? property.numberOfReviews : null,
          };
          for (let i = 0; i < property.starRatings; i++) {
            newList.rating.push({
              icon: 'bx bxs-star',
            });
          }
          this.singleListingsBox.push(newList);
        });
      }
    });
    this.subscriptions.push(listingSub);
  }

  pageTitleContent = [
    {
      title: 'Author',
      backgroundImage: 'assets/img/page-title/page-title1.jpg',
    },
  ];

  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    items: 1,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
  };
  ngOnDestroy(): void {}
}
