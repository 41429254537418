<app-navbar-style-two></app-navbar-style-two>

<!-- Start Listings Details Area -->
<section class="listings-details-area pb-70">
    <div class="listings-details-image">
        <img src={{getCoverImg()}} alt="image" class="img-responsive"
            style="object-fit: fill;width: 100%;height: 500px;">
        <div class="container">
            <div class="container">
                <div class="listings-details-content">
                    <span class="meta"><i
                            class="flaticon-furniture-and-household"></i>{{singleListing.propertyCategory}}</span>
                    <h3>{{singleListing.title}}</h3>
                    <div class="rating d-flex align-items-center">
                        <ng-container *ngFor="let star of [].constructor(5); let i = index">
                            <span class="bx"
                                [ngClass]="{'bxs-star checked': true, 'bxs-star': i >= singleListing.starRatings}"></span>
                        </ng-container>
                    </div>


                    <ul class="d-flex align-items-center">
                        <li class="phone-number">
                            <a href="#"><i class='bx bx-phone-call'></i> {{singleListing.phoneNumber}}</a>
                        </li>
                        <li class="time">
                            <i class='bx bx-time-five'></i>
                            <span>Currently Open</span>
                            {{getFormattedOpeningHoursForToday()}}
                        </li>
                        <li class="location">
                            <i class='bx bx-map'></i>
                            <span>Location</span>
                            {{getLosation(

                            )}}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- <div class="container-fluid">
            <ul class="share-save">
                <li>
                    <div class="share">
                        <i class='bx bx-share-alt'></i> Share
                    </div>
                    <div class="social">
                        <a href="#" target="_blank"><i class='bx bxl-facebook'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-pinterest'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-twitter'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a>
                        <a href="#" target="_blank"><i class='bx bxl-whatsapp'></i></a>
                    </div>
                </li>
                <li><a routerLink="/single-listings"><i class='bx bx-heart'></i> Save</a></li>
            </ul>
        </div> -->
    </div>
    <div class="listings-details-nav">
        <div class="container">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <span class="nav-link" (click)="switchTab($event, 'tab1')"
                        [ngClass]="{'active': currentTab === 'tab1'}">
                        Overview
                    </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="switchTab($event, 'tab2')"
                        [ngClass]="{'active': currentTab === 'tab2'}">
                        Gallery
                    </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="switchTab($event, 'tab3')"
                        [ngClass]="{'active': currentTab === 'tab3'}">
                        Pricing
                    </span>
                </li>
                <li class="nav-item">
                    <span class="nav-link" (click)="switchTab($event, 'tab4')"
                        [ngClass]="{'active': currentTab === 'tab4'}">
                        Review
                    </span>
                </li>
                <li class="nav-item" *ngIf="role == 'Guest'">
                    <span class="nav-link" (click)="switchTab($event, 'tab5')"
                        [ngClass]="{'active': currentTab === 'tab5'}">
                        Add Review
                    </span>
                </li>
            </ul>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="listings-details-desc" *ngIf="currentTab === 'tab1'">
                    <h3>Details</h3>
                    <p>{{singleListing.description}}</p>
                    <h3>Amenities</h3>
                    <ul class="amenities-list">
                        <li *ngFor="let amenity of singleListing.amenityDetails;">
                            <span><i class='bx bx-check'></i> {{amenity.name}}</span>
                        </li>
                    </ul>
                    <h3>Gallery</h3>
                    <div id="gallery">
                        <div class="gallery-slides" (click)="onOpenSlider()" style="cursor: pointer;">
                            <owl-carousel-o [options]="galleryOptions">
                                <ng-template carouselSlide *ngFor="let Image of singleListing.propertyPicture;">
                                    <div class="single-image-box">
                                        <img [src]="environmentVar.blobUrl+Image.path" alt="image"
                                            style="object-fit: cover;width: 100%;height: 200px;">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                    <h3>Pricing</h3>
                    <div id="pricing">
                        <ul class="pricing-list">
                            <li>Per night <span>{{ '$' + singleListing.pricePerNight }}</span></li>
                            <!-- <li>Burger <span>$10</span></li>
                            <li>Cool Drink <span>$12</span></li>
                            <li>Fried Rice <span>$08</span></li>
                            <li>Orange Juice <span>$05</span></li> -->
                        </ul>
                    </div>
                    <h3>Guest capacity</h3>
                    <div id="pricing">
                        <ul class="pricing-list">
                            <li>Max Guest<span>{{singleListing.maxGuests}}</span></li>
                            <!-- <li>Burger <span>$10</span></li>
                            <li>Cool Drink <span>$12</span></li>
                            <li>Fried Rice <span>$08</span></li>
                            <li>Orange Juice <span>$05</span></li> -->
                        </ul>
                    </div>
                    <ng-container *ngIf="singleListing.starRatings > 0">
                        <h3>Review</h3>
                        <div class="listings-review">
                            <div class="rating d-flex align-items-center">
                                <ng-container *ngFor="let star of [].constructor(5); let i = index">
                                    <span class="bx"
                                        [ngClass]="{'bxs-star checked': i < singleListing.starRatings, 'bxs-star': i >= singleListing.starRatings}"></span>
                                </ng-container>
                            </div>

                        </div>
                    </ng-container>

                    <div class="write-a-review" *ngIf="(Authorize$ | async) && role  == 'Guest'">
                        <h4>Tell people what you think.</h4>
                        <p>Help others by sharing your experience with this business.</p>
                        <a class="default-btn" (click)="openAddReview()">Write A Review</a>
                    </div>
                    <div id="review" *ngFor="let review of propertyReviews$ | async;">
                        <div class="listings-review-comments">
                            <div class="user-review">
                                <div class="row m-0">
                                    <div class="col-lg-4 col-md-4 p-0">
                                        <div class="user">
                                            <div class="d-flex">
                                                <div >
                                                    <img *ngIf="review.reviewPicture; else defaultImage" 
                                                    [src]="environmentVar.blobUrl + review.reviewPicture"
                                                    class="rounded-circle" 
                                                    alt="User profile image" width="70" height="70">
                                               
                                               <ng-template #defaultImage>
                                                   <img src="assets/img/download.png" 
                                                        class="rounded-circle" 
                                                        alt="Default profile image">
                                               </ng-template>
                                               
                                                </div>
                                                <div class="title">
                                                    <h4>{{review.reviewerName}}</h4>
                                                    <span>{{review.reviewerAddress}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 p-0">
                                        <div class="comments">
                                            <div class="rating">
                                                <ng-container *ngFor="let star of [].constructor(5); let i = index">
                                                    <span class="bx"
                                                        [ngClass]="{'bxs-star checked': true, 'bxs-star': i >= review.rating}"></span>
                                                </ng-container>
                                            </div>
                                            <p>{{review.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="add-review" *ngIf="isAddReview">
                        <div class="review-form-wrapper">
                            <h3>Add A Review</h3>
                            <form [formGroup]="reviewFormGroup">
                                <!-- Ratings Input -->
                                <div class="star-rating">
                                    <label for="rating" class="rating-label">Ratings:</label>
                                    <star-rating-control [starType]="'svg'"
                                        formControlName="ratings"></star-rating-control>
                                </div>
                                <div class="row">
                                    <!-- Name Input -->
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" formControlName="name"
                                                placeholder="Name *">
                                            <div
                                                *ngIf="reviewFormGroup.controls['name'].invalid && reviewFormGroup.controls['name'].touched">
                                                <small class="text-danger">Name is required.</small>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Email Input -->
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control" formControlName="email"
                                                placeholder="Email *">
                                            <div
                                                *ngIf="reviewFormGroup.controls['email'].invalid && reviewFormGroup.controls['email'].touched">
                                                <small class="text-danger">Email is required.</small>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Comment Input -->
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <textarea placeholder="Your review" class="form-control"
                                                formControlName="comment" cols="30" rows="6"></textarea>
                                            <div
                                                *ngIf="reviewFormGroup.controls['comment'].invalid && reviewFormGroup.controls['comment'].touched">
                                                <small class="text-danger">Comment is required.</small>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Submit Button -->
                                    <div class="col-lg-12 col-md-12">
                                        <button type="button" (click)="onSubmit()"
                                            class="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>


                        </div>
                    </div>
                    <h3>Other Nearby Services</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6" *ngFor="let Content of singleListingsBox;">
                            <ng-container *ngIf="Content.id != propertyProfileId">
                                <div class="single-listings-box"
                                    (click)="moveToSingleListing(Content.detailsLink,Content.id)"
                                    style="margin-bottom: 20px;box-shadow: none;border-radius: 15px;">
                                    <div class="listings-image">
                                        <div class="listings-image-slides">
                                            <owl-carousel-o [options]="customOptions">
                                                <ng-template carouselSlide *ngFor="let Image of Content.mainImg;">
                                                    <div class="single-image" style="height: 220px;">
                                                        <img [src]="Image.img" class="img-responsive" alt="image"
                                                            style="object-fit: cover; width: 100%;height: 220px;">
                                                    </div>
                                                </ng-template>
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                    <div class="listings-content" style="padding: 8px 5px 3px !important;">
                                        <ul class="listings-meta" style="margin-bottom: 0px;">
                                            <li
                                                style="margin-right: 15px;margin-bottom: 10px;font-weight: 400;font-size: 11px;">
                                                <i class="flaticon-furniture-and-household"></i> {{Content.category}}
                                            </li>
                                            <li
                                                style="margin-right: 15px;margin-bottom: 10px;font-weight: 400;font-size: 11px;">
                                                <i class="flaticon-pin"></i>{{Content.location}}
                                            </li>
                                        </ul>
                                        <h3 style="margin-bottom: 7px;font-size: 14px;font-weight: 500;"
                                            (click)="moveToSingleListing(Content.detailsLink,Content.id)">
                                            {{Content.title}}</h3>
                                        <div class="d-flex align-items-center justify-content-between">
                                            <div class="rating" *ngIf="Content.rating != null">
                                                <i class='{{Star.icon}}' *ngFor="let Star of Content.rating;"></i>
                                                <span class="count"
                                                    *ngIf="Content.ratingCount > 0">({{Content.ratingCount}})</span>
                                            </div>

                                            <div class="price" style="font-weight: 500;">
                                                ${{Content.price}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>

                        </div>
                    </div>
                </div>
                <div class="listings-details-desc" *ngIf="currentTab === 'tab2'">
                    <h3>Gallery</h3>
                    <div class="gallery-slides" (click)="onOpenSlider()" style="cursor: pointer;">
                        <owl-carousel-o [options]="galleryOptions" >
                            <ng-template carouselSlide *ngFor="let Image of singleListing.propertyPicture;">
                                <div class="single-image-box">
                                    <img [src]="environmentVar.blobUrl+Image.path" alt="image"
                                        style="object-fit: cover;width: 100%;height: 200px;">
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
                <div class="listings-details-desc" *ngIf="currentTab === 'tab3'">
                    <h3>Pricing</h3>
                    <ul class="pricing-list">
                        <li>Per night <span>{{ '$' + singleListing.pricePerNight }}</span></li>
                    </ul>
                </div>
                <div class="listings-details-desc" *ngIf="currentTab === 'tab4'">
                    <div id="review" *ngFor="let review of propertyReviews$ | async;">
                        <div class="listings-review-comments">
                            <div class="user-review">
                                <div class="row m-0">
                                    <div class="col-lg-4 col-md-4 p-0">
                                        <div class="user">
                                            <div class="d-flex">
                                                <div >
                                                    <img *ngIf="review.reviewPicture; else defaultImage" 
                                                    [src]="environmentVar.blobUrl + review.reviewPicture"
                                                    class="rounded-circle" 
                                                    alt="User profile image" width="70" height="70">
                                               
                                               <ng-template #defaultImage>
                                                   <img src="assets/img/download.png" 
                                                        class="rounded-circle" 
                                                        alt="Default profile image">
                                               </ng-template>
                                               
                                                </div>
                                                <div class="title">
                                                       <h4>{{review.reviewerName}}</h4>
                                                    <span>{{review.reviewerAddress}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 col-md-8 p-0">
                                        <div class="comments">
                                            <div class="rating">
                                                <ng-container *ngFor="let star of [].constructor(5); let i = index">
                                                    <span class="bx"
                                                        [ngClass]="{'bxs-star checked': true, 'bxs-star': i >= review.rating}"></span>
                                                </ng-container>
                                            </div>
                                            <p>{{review.comment}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="listings-details-desc" *ngIf="currentTab === 'tab5' && role == 'Guest'">
                    <div class="review-form-wrapper">
                        <h3>Add A Review</h3>
                        <form [formGroup]="reviewFormGroup">
                            <!-- Ratings Input -->
                            <div class="star-rating">
                                <label for="rating" class="rating-label">Ratings:</label>
                                <star-rating-control [starType]="'svg'" formControlName="ratings"></star-rating-control>
                            </div>
                            <div class="row">
                                <!-- Name Input -->
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" class="form-control" formControlName="name"
                                            placeholder="Name *">
                                        <div
                                            *ngIf="reviewFormGroup.controls['name'].invalid && reviewFormGroup.controls['name'].touched">
                                            <small class="text-danger">Name is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <!-- Email Input -->
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" class="form-control" formControlName="email"
                                            placeholder="Email *">
                                        <div
                                            *ngIf="reviewFormGroup.controls['email'].invalid && reviewFormGroup.controls['email'].touched">
                                            <small class="text-danger">Email is required.</small>
                                        </div>
                                    </div>
                                </div>
                                <!-- Comment Input -->
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea placeholder="Your review" class="form-control"
                                            formControlName="comment" cols="30" rows="6"></textarea>
                                        <div
                                            *ngIf="reviewFormGroup.controls['comment'].invalid && reviewFormGroup.controls['comment'].touched">
                                            <small class="text-danger">Comment is required.</small>
                                        </div>
                                    </div>
                                </div>

                                <!-- Submit Button -->
                                <div class="col-lg-12 col-md-12">
                                    <button type="button" (click)="onSubmit()" class="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="listings-sidebar">
                    <!-- Reactive Form for Check-in, Check-out, and Guests -->
                    <div class="listings-widget" *ngIf="((Authorize$ | async) && role == 'Guest')">
                        <h3>Book Now</h3>
                        <form [formGroup]="bookingForm">
                            <div class="form-group">
                                <label for="checkin">Check-in</label>
                                <!-- <input type="date" id="checkin" class="form-control" formControlName="checkin"
                                    [min]="today" />
                                <div *ngIf="bookingForm.get('checkin').touched && bookingForm.get('checkin').invalid"
                                    class="error">
                                    Check-in date is required.
                                </div> -->
                                <mat-form-field appearance="outline" class="w-100 date-field">
                                  <input matInput [matDatepicker]="picker" formControlName="checkin" readonly placeholder="Select Check-in date">
                                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                  <mat-datepicker #picker panelClass="custom-datepicker"></mat-datepicker>
                                  <mat-error class="error">Check-in date is required.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group" style="padding-top: 15px;">
                                <label for="checkout">Check-out</label>
                                <!-- <input type="date" id="checkout" class="form-control" formControlName="checkout"
                                    [min]="today" />
                                <div *ngIf="bookingForm.get('checkout').touched && bookingForm.get('checkout').invalid"
                                    class="error">
                                    Check-out date is required.
                                </div> -->
                                <mat-form-field appearance="outline" class="w-100 date-field">
                                  <input matInput [matDatepicker]="picker1" formControlName="checkout" readonly placeholder="Select Check-out date">
                                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                  <mat-datepicker #picker1 panelClass="custom-datepicker"></mat-datepicker>
                                  <mat-error class="error">Check-out date is required.</mat-error>
                                </mat-form-field>
                            </div>

                            <div class="form-group" style="padding-top: 15px;">
                                <label for="guests">Guests</label>
                                <input type="number" id="guests" class="form-control" formControlName="guests"
                                    min="1" />
                                <div *ngIf="bookingForm.get('guests').touched && bookingForm.get('guests').invalid"
                                    class="error">
                                    Number of guests is required (at least 1 guest).
                                </div>
                            </div>

                            <div class="book_listings" style="padding-top: 15px;">
                                <button type="button" class="default-btn"
                                    [ngClass]="{'disabled-btn': bookingForm.invalid || (!(Authorize$ | async) && role == 'Guest')}"
                                    [disabled]="(bookingForm.invalid || (!(Authorize$ | async)&& role == 'Guest'))"
                                    (click)="moveToBooking()">
                                    Book Now
                                </button>
                            </div>
                        </form>


                    </div>



                    <!-- Contact Details Widget -->
                    <div class="listings-widget listings_contact_details">
                        <h3>Contact Details</h3>
                        <ul>
                            <li><i class='bx bx-globe'></i> <a href="#" target="_blank">{{singleListing.ownerEmail}}</a>
                            </li>
                            <li><i class='bx bx-phone-call'></i> <a
                                    href="tel:+2122791456">{{singleListing.phoneNumber}}</a></li>
                            <li><i class='bx bx-map'></i> {{ singleListing.city }}, {{ singleListing.country }}</li>
                        </ul>
                    </div>

                    <!-- Hosted By Widget -->
                    <div class="listings-widget listings_author">
                        <h3>Hosted By</h3>
                        <div class="author">
                            <div class="d-flex align-items-center">
                                <img *ngIf="singleListing?.ownerProfilePicUrl; else defaultImage"
                                    [src]="singleListing.ownerProfilePicUrl" class="rounded-circle"
                                    alt="User profile image">

                                <ng-template #defaultImage>
                                    <img src="assets/img/download.png" class="rounded-circle"
                                        alt="Default profile image">
                                </ng-template>

                                <div class="title">
                                    <h4><a>{{ singleListing.ownerName }}</a></h4>
                                </div>
                            </div>
                            <div class="author-profile"
                                v-if="singleListing.fbLink || singleListing.xLink || singleListing.instaLink">
                                <div class="row align-items-center">
                                    <div class="col-lg-5 col-md-5">
                                        <!-- <a class="view-profile">View Profile</a> -->
                                    </div>
                                    <div class="col-lg-7 col-md-7">
                                        <ul class="social">
                                            <li v-if="singleListing.fbLink"><a :href="singleListing.fbLink"
                                                    target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                            <li v-if="singleListing.xLink"><a :href="singleListing.xLink"
                                                    target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                            <li v-if="singleListing.instaLink"><a :href="singleListing.instaLink"
                                                    target="_blank"><i class='bx bxl-instagram'></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Details Area -->

<app-footer-style-two></app-footer-style-two>

<div *ngIf="propertyCarousel" class="modal fade show d-block" tabindex="-1"
            aria-hidden="true">
            <div
              class="modal-dialog modal-dialog-centered"
              style="z-index: 9999;width: 950px;height: 600px;max-width: 950px;max-height: 600px">
                <div class="modal-content">
                    <button type="button" class="close" (click)="closePopup()" style="position: absolute;
                      right: 0px;
                      top: 0px;
                      opacity: 1 !important;
                      visibility: visible !important;
                      background-color: #ffffff;
                      text-align: center;
                      text-shadow: unset;
                      border-radius: 50%;
                      font-size: 20px;
                      border: none;
                      padding: 0;
                      color: #161515;
                      padding: 0px 0 0;
                      z-index: 1999;">
                          <i class='bx bx-x'></i>
                      </button>
                    <div class="modal-body">
                      <div
                      id="carouselExample"
                      class="carousel"
                      data-bs-ride="carousel"
                    >
                      <div class="carousel-inner">
                        <div
                          class="carousel-item"
                          *ngFor="let image of singleListing.propertyPicture; let i = index"
                          [ngClass]="{ active: i === 0 }"
                        >
                          <img
                            [src]="environmentVar.blobUrl + image.path"
                            class="d-block w-100"
                            alt="Image {{ i + 1 }}"
                          />
                        </div>
                      </div>
                      <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="prev"
                      >
                        <span class="carousel-control-prev-icon"></span>
                        <span class="visually-hidden">Previous</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExample"
                        data-bs-slide="next"
                      >
                        <span class="carousel-control-next-icon"></span>
                        <span class="visually-hidden">Next</span>
                      </button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>

