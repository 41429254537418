<div id="map" style="height: 100%; width: 100%; position: relative;">
  <!-- Action Button inside the map container -->
  <!-- <button *ngIf="!isReadOnly" class="action-button" (click)="onActionButtonClick()">Done</button> -->
  <!-- <mat-form-field *ngIf="isStopBound" appearance="fill" class=" autocomplete-field">
    <input type="text" matInput placeholder="Which city... Search destination" (input)="onCitySearchChange($event)"
      [matAutocomplete]="auto">

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayCity">
      <ng-container *ngIf="filteredCities.length > 5; else noScroll">
        <cdk-virtual-scroll-viewport itemSize="48" class="viewport" style="height: 200px;">
          <mat-option *cdkVirtualFor="let city of filteredCities" [value]="city"
            (onSelectionChange)="onCitySelect(city)">
            {{ city.name }}
          </mat-option>
        </cdk-virtual-scroll-viewport>
      </ng-container>

      <ng-template #noScroll>
        <div>
          <mat-option *ngFor="let city of filteredCities" [value]="city" (onSelectionChange)="onCitySelect(city)">
            {{ city.name }}
          </mat-option>
        </div>
      </ng-template>
    </mat-autocomplete>
  </mat-form-field> -->
  <div class="switch-container" (click)="$event.stopPropagation()">

    <div class="map-container">
      <div id="MapSearch" *ngIf="isReadOnly">
        <label class="map-label">Map Search</label>
        <label class="switch" style="width: 40px; height: 20px;">
          <input type="checkbox" style="height: 12px;width: 13px; left: 3px; " (change)="mapSearch($event)">
          <span class="slider round" style="width: 46px;"></span>
        </label>
      </div>

      <div id="MapTypeSwitch" class="d-flex align-items-center mt-3">
        <label class="map-label">Map View:</label>
        <!-- <select (change)="switchMapView($event)">
          <option value="google">Google Map</option>
          <option value="osm">OpenStreetMap</option>
          <option value="satellite">Satellite View</option>
        </select> -->
        <ng-select
          [items]="map_views"
          bindLabel="name"
          bindValue="id"
          [multiple]="false"
          placeholder="Select map view"
          [(ngModel)]="selectedMapView"
          (change)="switchMapView($event)">
        </ng-select>
      </div>
    </div>
  </div>
</div>
