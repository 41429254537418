import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavbarStyleTwoComponent } from '../navbar-style-two/navbar-style-two.component';

@Component({
  selector: 'app-stripe-success',
  standalone: true,
  imports: [NavbarStyleTwoComponent],
  templateUrl: './stripe-success.component.html',
  styleUrl: './stripe-success.component.scss'
})
export class StripeSuccessComponent {
  sessionId: string | null = null;
  successMessage: string = '';

  constructor(private route: ActivatedRoute, private http: HttpClient) {}

  ngOnInit(): void {
    // Get the session_id from the query parameters
    this.sessionId = this.route.snapshot.queryParamMap.get('session_id');

    if (this.sessionId) {
      
      this.successMessage = `Your payment was successful! Session ID: ${this.sessionId}`;
    } else {
      this.successMessage = 'Payment successful, but no session ID was provided.';
    }
  }
}
