import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit, HostListener, Injector, OnDestroy } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Login } from 'src/app/state/snappstay.action';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { Authorize } from 'src/app/models/authorize.model';
@Component({
  selector: 'app-navbar-style-one',
  templateUrl: './navbar-style-one.component.html',
  styleUrls: ['./navbar-style-one.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AsyncPipe],
})
export class NavbarStyleOneComponent
  extends SnappstayBaseComponent
  implements OnInit,OnDestroy
{
  loginFormGroup!: FormGroup;

  constructor(
    inject: Injector,
    private fb: FormBuilder,
  ) {
    super(inject);
  }
  ngOnInit(): void {
    this.initFormGroup();
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }
  initFormGroup() {
    this.loginFormGroup = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  async login() {
    if (this.loginFormGroup.touched && this.loginFormGroup.valid) {
      try {
        const loginFormRequest = this.loginFormGroup.getRawValue();
        const login$ = this.accountApiService.login(loginFormRequest);
        const login = await lastValueFrom(login$, {
          defaultValue: undefined,
        });
        if (login) {
          this.store.dispatch(new Login(login as Authorize ?? undefined));
          const isAuthorizeSub = this.store
            .select(SnappstayState.Authorize)
            .subscribe((authorize) => {
              if (authorize) {
 
                this.closeSigninSignupPopup();
              }
            });
          this.subscriptions.push(isAuthorizeSub);
          this.toastr.success("Welcome "+`${loginFormRequest.email}`,"Login Successfull", {
            timeOut: 3000,positionClass: 'toast-bottom-right',
          });
        }
      } catch (e: any) {
        let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
        if (e?.error) {
          message = e.error.message ? e.error.message : e.error.errors[0];
          this.toastr.error(message,"Please try again later", {
          timeOut: 3000,positionClass: 'toast-bottom-right',
        });
        }
         
        this.store.dispatch(new Login(undefined));
      } finally {
      }
    }
  }

  logOut(){
    this.store.dispatch(new Login(undefined));
    this.router.navigate(["/"]).then();
  }
  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  // Signin/Signup Popup
  isSigninSignupOpen = false;
  openSigninSignupPopup(): void {
    this.isSigninSignupOpen = true;
  }
  closeSigninSignupPopup(): void {
    this.isSigninSignupOpen = false;
  }

  // Signin/Signup Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
