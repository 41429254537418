import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { ApplicationApiUrls } from 'src/app/constants/api-url-constants';
import { Authorize } from 'src/app/models/authorize.model';
import { ChangePasswordRequest } from 'src/app/models/request-models/change-password.request';
import { CreatUserRequest } from 'src/app/models/request-models/create-user';
import { loginRequest } from 'src/app/models/request-models/login-request';
import { ApiResponse } from 'src/app/models/response-model/api-response';
import { UserRegestrationResponse } from 'src/app/models/response-model/user-regestration-response-model';
import { UserProfile } from 'src/app/models/user-profile.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountsApiService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  login(credentials: loginRequest): Observable<Authorize> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.Login}`, credentials)
      .pipe(
        map((data: ApiResponse<Authorize>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  googleLogin(token: string): Observable<Authorize> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.GoogleLogin}`, token)
      .pipe(
        map((data: ApiResponse<Authorize>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  appleLogin(token: string): Observable<Authorize> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.AppleLogin}`, token)
      .pipe(
        map((data: ApiResponse<Authorize>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  createUser(userData: CreatUserRequest): Observable<any> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.CreateUser}`, userData)
      .pipe(
        map((data: ApiResponse<UserRegestrationResponse>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  GetMyProfile(): Observable<UserProfile> {
    return this.http
      .get(`${this.apiUrl}${ApplicationApiUrls.GetMyProfile}`)
      .pipe(
        map((data: ApiResponse<UserProfile>) => {
          return data.result!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  UpdateProfile(request: UserProfile): Observable<boolean> {
    return this.http
      .post(`${this.apiUrl}${ApplicationApiUrls.UpdateProfile}`, request)
      .pipe(
        map((data: ApiResponse<boolean>) => {
          return data.successful!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  changePassword(request: ChangePasswordRequest): Observable<boolean> {
    return this.http
      .post(
        `${this.apiUrl}${ApplicationApiUrls.ChangeExpiredPassword}`,
        request
      )
      .pipe(
        map((data: ApiResponse<boolean>) => {
          return data.successful!;
        }),
        catchError((err) => {
          throw err;
        })
      );
  }

  changeProfilePic(profilePicture: File): Observable<ApiResponse<string>> {
    const formData = new FormData();
    formData.append('profilePicture', profilePicture);
    return this.http
      .post<ApiResponse<string>>(
        `${this.apiUrl}${ApplicationApiUrls.UpdateProfileImage}`,
        formData
      )
      .pipe(
        map((response) => response),
        catchError((err) => {
          throw err;
        })
      );
  }
}
