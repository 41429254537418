import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { NgxPaginationModule } from 'ngx-pagination';

import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule } from '@angular/forms';
import { NavbarStyleTwoComponent } from '../../common/navbar-style-two/navbar-style-two.component';
import { CommonModule } from '@angular/common';
import { RouterLinkWithHref, RouterLinkActive } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Amenity } from 'src/app/models/listing-model/amenity';
import { ListingResponse } from 'src/app/models/listing-model/listing-response.model';
import { PropertyType } from 'src/app/models/lookup/property-type';
import { GetListingRequest } from 'src/app/models/request-models/get-listing.request';
import { BaseGridResponse } from 'src/app/models/response-model/base-grid-response';
import { GetPropertyRequest, SetIsLuxury, SetListing } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { CarouselModule, OwlOptions } from 'ngx-owl-carousel-o';
import { MapMarker } from '../../shared/map/map-marker';
import { MapComponent } from '../../shared/map/map-component/map-component.component';
import {
  CdkScrollableModule,
  CdkVirtualScrollViewport,
  ScrollingModule,
} from '@angular/cdk/scrolling';
import { NgLabelTemplateDirective, NgOptionTemplateDirective, NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';

@Component({
  selector: 'app-vertical-listings-left-sidebar',
  templateUrl: './vertical-listings-left-sidebar.component.html',
  styleUrls: ['./vertical-listings-left-sidebar.component.scss'],
  standalone: true,
  imports: [
    FooterStyleTwoComponent,
    NgxPaginationModule,
    SelectDropDownModule,
    FormsModule,
    NavbarStyleTwoComponent,
    CommonModule,
    RouterLinkWithHref,
    RouterLinkActive,
    CommonModule,
    CarouselModule,
    MapComponent,
    ScrollingModule,
    CdkVirtualScrollViewport,
    NgSelectModule
  ],
})
export class VerticalListingsLeftSidebarComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  @Select(SnappstayState.Listings)
  listings$!: Observable<BaseGridResponse<ListingResponse[]>>;

  @Select(SnappstayState.PropertyType)
  propertyType$!: Observable<PropertyType[]>;

  @Select(SnappstayState.Amenities)
  amenities$!: Observable<Amenity[]>;

  singleListingsBox: any[] = [];
  amenities: Amenity[] = [];
  propertyType: PropertyType[] = [];
  baseGridResponse: BaseGridResponse<ListingResponse[]> | undefined;
  isFeaturesOpen: boolean = false;
  isCategoriesOpen: boolean = true;
  loading: boolean = false;
  markers: MapMarker[] = [];
  bounds: any[] = [];
  isReadOnly: boolean = true;
  priceRanges = [
    {
      name: '$0 - $50',
      min: 0,
      max: 50,
    },
    {
      name: '$50 - $100',
      min: 50,
      max: 100,
    },
    {
      name: '$100 - $200',
      min: 100,
      max: 200,
    },
    {
      name: '$200 - $500',
      min: 200,
      max: 500,
    },
    {
      name: '$500 - $850',
      min: 500,
      max: 850,
    },
    {
      name: '$850 - $999',
      min: 850,
      max: 999,
    },
  ];
  selectedPriceRange: any | undefined;
  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.markers = [];
    this.getAmenity();
    this.getPropertyTypes();
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      isLuxury: this.IsLuxury,
    };
    if (!this.store.selectSnapshot(SnappstayState.GetListingRequest)) {
      this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
      this.getListings(
        this.store.selectSnapshot(SnappstayState.GetListingRequest)
      ).then((x) => {
        this.store.dispatch(new GetPropertyRequest(undefined));
      });
    } else {
      this.getListings(
        this.store.selectSnapshot(SnappstayState.GetListingRequest)
      ).then((x) => {
        this.store.dispatch(new GetPropertyRequest(undefined));
      });
    }
    this.resetOption = [this.options[0]];
    this.listingsSub();
    this.amenitiesSub();
    this.propertyTypeSub();
    this.store.dispatch(new GetPropertyRequest(undefined));
  }
  onPriceRangeChange(event: any) {
    this.selectedPriceRange = event.value;
    console.log('Selected Price Range:', this.selectedPriceRange);
    const propertyTypeNames = this.singleSelect.map((x) => x.name);
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      propertyCategorys:
        propertyTypeNames.length > 0 ? propertyTypeNames : null,
      priceMin: this.selectedPriceRange
        ? this.selectedPriceRange.min
        : undefined,
      priceMax: this.selectedPriceRange
        ? this.selectedPriceRange.max
        : undefined,
      isLuxury: this.IsLuxury,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
      // this.propertyType.forEach(x => {x.isChecked = false});
    });
  }
  onPageChange(page: number) {
    this.baseGridResponse.pageNumber = page;
    const amenitiesIds = this.amenities
      .filter((x) => x.isChecked)
      .map((x) => x.amenityId);
    const propertyTypeNames = this.propertyType
      .filter((x) => x.isChecked)
      .map((x) => x.name);
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: page,
      pageSize: this.baseGridResponse.pageSize,
      propertyCategorys:
        propertyTypeNames.length > 0 ? propertyTypeNames : null,
      amenitiesId: amenitiesIds.length ? amenitiesIds : null,
      isLuxury: this.IsLuxury,
    };
    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }
  onMapClick($event) {
    console.log('Map clicked at:', $event);
  }
  searchOnZoom($event) {
    console.log('Map Search at:', $event);
    const bounds = $event.bounds;
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      northEastlat: bounds._northEast.lat,
      northEastlng: bounds._northEast.lng,
      southWestlat: bounds._southWest.lat,
      southWestlng: bounds._southWest.lng,
      isLuxury: this.IsLuxury,
    };
    if (!this.store.selectSnapshot(SnappstayState.GetListingRequest)) {
      this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
      this.getListings(
        this.store.selectSnapshot(SnappstayState.GetListingRequest)
      ).then((x) => {
        this.store.dispatch(new GetPropertyRequest(undefined));
      });
    }
  }
  toggleFeatures() {
    this.isFeaturesOpen = !this.isFeaturesOpen;
  }

  toggleCategories() {
    this.isCategoriesOpen = !this.isCategoriesOpen;
  }
  moveToSingleListing(id: number) {
    this.router.navigate(['/single-listings', id]).then();
  }

  getLeafLetMouseEvent(param: any) {
    const event = param.target.value;
  }

  amenitiesSub() {
    const AmenitiesSub = this.amenities$.subscribe((x) => {
      if (x) {
        this.amenities = x;
      } else {
        this.amenities = [];
      }
    });
    this.subscriptions.push(AmenitiesSub);
  }

  onAmenityChange(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const amenitiesIds = this.multiSelect.map((x) => x.amenityId);
    const propertyTypeNames = this.singleSelect.map((x) => x.name);
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 1,
      isActive: true,
      propertyCategorys:
        propertyTypeNames.length > 0 ? propertyTypeNames : null,
      amenitiesId: amenitiesIds.length ? amenitiesIds : null,
      isLuxury: this.IsLuxury,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
    });
  }

  onPropertyTypeChange(event: Event) {
    const propertyTypeNames = this.singleSelect.map((x) => x.name);
    const amenitiesIds = this.multiSelect.map((x) => x.amenityId);
    const GetAllPropertyProfile: GetListingRequest = {
      pageNumber: 1,
      pageSize: 10,
      isActive: true,
      propertyCategorys:
        propertyTypeNames.length > 0 ? propertyTypeNames : null,
      // amenitiesId: amenitiesIds.length ? amenitiesIds : null,
      priceMin: this.selectedPriceRange
        ? this.selectedPriceRange.min
        : undefined,
      priceMax: this.selectedPriceRange
        ? this.selectedPriceRange.max
        : undefined,
      isLuxury: this.IsLuxury,
    };

    this.store.dispatch(new GetPropertyRequest(GetAllPropertyProfile));
    this.getListings(
      this.store.selectSnapshot(SnappstayState.GetListingRequest)
    ).then((x) => {
      this.store.dispatch(new GetPropertyRequest(undefined));
      // this.propertyType.forEach(x => {x.isChecked = false});
    });
  }

  propertyTypeSub() {
    const PropertyTypeSub = this.propertyType$.subscribe((value) => {
      if (value) {
        this.propertyType = [];
        value.forEach((x) => {
          x.isChecked = false;
          this.propertyType.push(x);
        });
      } else {
        this.propertyType = [];
      }
    });
    this.subscriptions.push(PropertyTypeSub);
  }

  listingsSub() {
    const listingSub = this.listings$.subscribe((item) => {
      this.baseGridResponse = item;
      this.singleListingsBox = [];
      this.bounds = [];
      this.markers = [];

      if (item && item.result.length > 0) {
        item.result.forEach((property: ListingResponse) => {
          const images: any[] = [];
          property.propertyPicture.forEach((image) => {
            images.push({
              img: `${this.environmentVar.blobUrl}` + image.path,
            });
          });
          const newList = {
            mainImg: images,
            categoryLink: 'single-listings',
            category: property.propertyCategory,
            bookmarkLink: 'single-listings',
            location: `${property.city}-${property.country}`,
            title: property.title,
            price: property.pricePerNight,
            detailsLink: 'single-listings',
            authorImg: 'assets/img/user1.jpg',
            openORclose: 'Open Now',
            extraClass: 'status-open',
            authorName: 'James',
            rating: [],
            ratingCount: property.numberOfReviews,
            id: property.id,
          };
          for (let i = 0; i < property.starRatings; i++) {
            newList.rating.push({
              icon: 'bx bxs-star',
            });
          }
          if (property.latitude && property.longitude) {
            const marker: MapMarker = {
              name: property.title,
              city: property.city,
              country: property.country,
              address: property.address,
              price: property.pricePerNight,
              propertyType: property.propertyCategory,
              latLng: {
                latitude: property.latitude,
                longitude: property.longitude,
              },
              customData: {
                id: property.id,
                data: {
                  ratingCount: property.numberOfReviews,
                  rating: property.starRatings,
                  img:
                    `${this.environmentVar.blobUrl}` +
                    property.propertyPicture.at(0).path,
                  isImageShow: true,
                },
              },
            };
            this.bounds.push({
              lat: property.latitude,
              lng: property.longitude,
            });
            this.markers.push(marker);
          }
          this.singleListingsBox.push(newList);
        });
      }
    });
    this.subscriptions.push(listingSub);
  }

  pageTitleContent = [
    {
      title: 'Find Popular Places',
    },
  ];

  // Category Select
  singleSelect: any;
  multiSelect: any = [];
  stringArray: any = [];
  objectsArray: any = [];
  resetOption: any;
  config: any = {
    displayKey: 'name',
    search: true,
  };
  priceConfig: any = {
    displayKey: 'name',
    search: false,
  };
  options = [
    // Type here your category name
    {
      name: 'Restaurants',
    },
    {
      name: 'Events',
    },
    {
      name: 'Clothing',
    },
    {
      name: 'Bank',
    },
    {
      name: 'Fitness',
    },
    {
      name: 'Bookstore',
    },
  ];
  searchChange($event) {
    console.log($event);
  }
  reset() {
    this.resetOption = [];
  }

  // Ordering Select
  options2 = [
    {
      name: 'Recommended',
    },
    {
      name: 'Default',
    },
    {
      name: 'Popularity',
    },
    {
      name: 'Latest',
    },
    {
      name: 'Price: low to high',
    },
    {
      name: 'Price: high to low',
    },
  ];

  // All Listings
  singleListingsItem = [
    {
      mainImg: 'assets/img/listings/listings9.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Restaurant',
      location: 'New York, USA',
      title: 'The Mad Made Grill',
      price: 'Start From: $121',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user3.jpg',
      authorName: 'James',
      openORclose: 'Open Now',
      extraClass: 'status-open',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '18',
    },
    {
      mainImg: 'assets/img/listings/listings10.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Hotel',
      location: 'Los Angeles, USA',
      title: 'The Beverly Hills Hotel',
      price: 'Start From: $200',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user2.jpg',
      authorName: 'Sarah',
      openORclose: 'Open Now',
      extraClass: 'status-open',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '10',
    },
    {
      mainImg: 'assets/img/listings/listings11.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Shopping',
      location: 'Seattle, USA',
      title: 'Blue Water Shopping City',
      price: 'Start From: $500',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user5.jpg',
      authorName: 'Lina',
      openORclose: 'Open Now',
      extraClass: 'status-open',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '55',
    },
    {
      mainImg: 'assets/img/listings/listings12.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Restaurant',
      location: 'New York, USA',
      title: 'Chipotle Mexican Grill',
      price: 'Start From: $150',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user1.jpg',
      authorName: 'Taylor',
      openORclose: 'Close Now',
      extraClass: 'status-close',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '45',
    },
    {
      mainImg: 'assets/img/listings/listings17.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Restaurant',
      location: 'New York, USA',
      title: 'Thai Me Up Restaurant',
      price: 'Start From: $150',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user2.jpg',
      authorName: 'Sarah',
      openORclose: 'Close Now',
      extraClass: 'status-close',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '45',
    },
    {
      mainImg: 'assets/img/listings/listings16.jpg',
      categoryLink: 'single-listings',
      bookmarkLink: 'single-listings',
      category: 'Shopping',
      location: 'Seattle, USA',
      title: 'Skyview Shopping Complex',
      price: 'Start From: $500',
      detailsLink: 'single-listings',
      authorImg: 'assets/img/user5.jpg',
      authorName: 'Lina',
      openORclose: 'Open Now',
      extraClass: 'status-open',
      rating: [
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
        {
          icon: 'bx bxs-star',
        },
      ],
      ratingCount: '55',
    },
  ];

  verticalListings: number = 1;
  customOptions: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    autoplayHoverPause: true,
    autoplay: true,
    mouseDrag: false,
    items: 1,
    navText: [
      "<i class='flaticon-left-chevron'></i>",
      "<i class='flaticon-right-chevron'></i>",
    ],
  };
  ngOnDestroy(): void {
    this.store.dispatch(new SetIsLuxury(false));
    this.store.dispatch(new SetListing(undefined));
    this.store.dispatch(new GetPropertyRequest(undefined));
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
