import { CommonModule } from '@angular/common';
import {
  Component,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { lastValueFrom } from 'rxjs';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { DashboardNavbarComponent } from 'src/app/components/common/dashboard-navbar/dashboard-navbar.component';
import { DashboardSidemenuComponent } from 'src/app/components/common/dashboard-sidemenu/dashboard-sidemenu.component';
import { NavbarStyleTwoComponent } from 'src/app/components/common/navbar-style-two/navbar-style-two.component';
import { ApplicationConstant } from 'src/app/constants/application-constants';
import { UserFilterWithPagination } from 'src/app/models/request-models/user-filter-with-pagination-request';
import { UserDetails } from 'src/app/models/users-details.model';
import { SetAllUser } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';

@Component({
  selector: 'app-users-listing',
  templateUrl: './users-listing.component.html',
  styleUrl: './users-listing.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    NgxPaginationModule,
    NavbarStyleTwoComponent,
    DashboardSidemenuComponent,
    DashboardNavbarComponent,
  ],
})
export class UsersListingComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  userProfiles: UserDetails[] = [];
  userType: string | undefined;
  totalRecords = 0;
  pageSize = 8;
  currentPage = 1;
  sortField = 'fullName';
  sortDirection = 'asc';
  filter = '';
  showContextMenu: boolean = false;
  constructor(inject: Injector) {
    super(inject);
  }

  ngOnInit() {
    this.routeParamSub();
    this.SetAllUser();
  }

  private routeParamSub() {
    const user = this.activatedRoute.paramMap.subscribe((params: any) => {
      if (params.has('user')) {
        this.userType = params.params.user;
        const request: UserFilterWithPagination = {
          pageNumber: 1,
          pageSize: this.pageSize,
          sortColumn: 'Id',
          isHost: this.userType == 'Hosts' ? true : false,
        };
        this.loadUserProfiles(request);
      }
    });
    this.subscriptions.push(user);
  }

  SetAllUser() {
    const data = this.store
      .select(SnappstayState.AllUser)
      .subscribe((value) => {
        if (value.result && value.result.length > 0) {
          this.totalRecords = value.totalRecords;
          value.result.forEach((x) => {
            this.userProfiles.push({
              fullName: x.fullName,
              firstName: x.firstName,
              middleName: x.middleName,
              lastName: x.lastName,
              gender: x.gender,
              phoneNumber: x.phoneNumber,
              profileImageUrl: x.profileImageUrl
                ? `${this.environmentVar.blobUrl}` + x.profileImageUrl
                : 'assets/img/download.png',
              dateOfBirth: x.dateOfBirth ? x.dateOfBirth : new Date(1990, 1, 1),
              placeOfResidence: x.placeOfResidence,
              zipCode: x.zipCode,
              street: x.street,
              fbLink: x.fbLink ? x.fbLink : 'https://facebook.com/johndoe',
              instaLink: x.instaLink
                ? x.instaLink
                : 'https://instagram.com/johndoe',
              xLink: x.xLink ? x.xLink : 'https://x.com/johndoe',
            });
          });
          this.userProfiles = value.result;
        }
      });

    this.subscriptions.push(data);
  }
  async loadUserProfiles(request: UserFilterWithPagination) {
    try {
      const allUser$ = this.dashboardApiService.GetAllUser(request);
      const allUser = await lastValueFrom(allUser$, {
        defaultValue: undefined,
      });
      if (allUser) {
        this.store.dispatch(new SetAllUser(allUser));
      }
    } catch (e: any) {
      let message = ApplicationConstant.SOMETHING_WENT_WRONG_TRY_AGAIN;
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,
          positionClass: 'toast-bottom-right',
        });
      }
      this.store.dispatch(new SetAllUser(undefined));
    } finally {
    }
  }

  applyFilter(event: Event) {
    this.filter = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.currentPage = 1;
    const request: UserFilterWithPagination = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: 'Id',
      search: this.filter,
      isHost: this.userType == 'Hosts' ? true : false,
    };
    this.loadUserProfiles(request);
  }

  onPageChange(page: number) {
    this.currentPage = page;
    const request: UserFilterWithPagination = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: 'Id',
      isHost: this.userType == 'Hosts' ? true : false,
    };
    this.loadUserProfiles(request);
  }

  onSortChange(field: string) {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }
    const request: UserFilterWithPagination = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sortColumn: this.sortField,
      sortOrder: this.sortDirection,
      isHost: this.userType == 'Hosts' ? true : false,
    };
    this.loadUserProfiles(request);
  }

  onContextMenu(action: string, user: UserDetails) {
    // Handle context menu actions like 'view', 'edit', 'delete'
    console.log(`Action: ${action}, User: ${JSON.stringify(user)}`);
  }

  toggleContextMenu(content: any): void {
    this.userProfiles.forEach((item) => (item.showMenu = false));
    content.showMenu = !content.showMenu;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (!target.closest('.context-menu') && !target.closest('.context-btn')) {
      this.userProfiles.forEach((item) => (item.showMenu = false));
    }
  }
  ngOnDestroy(): void {}
}
