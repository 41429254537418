import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FooterStyleOneComponent } from '../../common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
    standalone: true,
    imports :[
        CommonModule,
        FooterStyleOneComponent,
        NavbarStyleOneComponent
    ]
})
export class GalleryComponent implements OnInit {

        

    ngOnInit(): void {
    }
    
    pageTitleContent = [
        {
            title: 'Gallery',
            backgroundImage: 'assets/img/page-title/page-title2.jpg'
        }
    ]

    singleGalleryItem = [
        {
            img: 'assets/img/gallery/gallery1.jpg'
        },
        {
            img: 'assets/img/gallery/gallery2.jpg'
        },
        {
            img: 'assets/img/gallery/gallery3.jpg'
        },
        {
            img: 'assets/img/gallery/gallery4.jpg'
        },
        {
            img: 'assets/img/gallery/gallery5.jpg'
        },
        {
            img: 'assets/img/gallery/gallery6.jpg'
        },
        {
            img: 'assets/img/gallery/gallery7.jpg'
        },
        {
            img: 'assets/img/gallery/gallery8.jpg'
        },
        {
            img: 'assets/img/gallery/gallery9.jpg'
        }
    ]

}