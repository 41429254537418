import { AsyncPipe, CommonModule } from '@angular/common';
import {
  Component,
  OnInit,
  HostListener,
  Injector,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  ReactiveFormsModule,
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { Login, SetUserProfile } from 'src/app/state/snappstay.action';
import { SnappstayState } from 'src/app/state/snappstay.state';
import { SnappstayBaseComponent } from '../../base-component/base.component';
import { CreatUserRequest } from 'src/app/models/request-models/create-user';
import { AccountComponent } from '../account/account.component';
import { AuthService } from '@auth0/auth0-angular';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AsyncPipe, AccountComponent,MatIconModule],
})
export class NavbarStyleTwoComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy
{
  loginFormGroup!: FormGroup;
  registerFormGroup!: FormGroup;
  visibilityIcon = false;
  currentTab = 'tab1';
  isSigninSignupOpen = false;
  isBecomeAHost = false;
  constructor(inject: Injector, private fb: FormBuilder,private auth: AuthService) {
    super(inject);
  }
  ngOnInit(): void {
    this.initLoginFormGroup();
    this.initRegisterFormGroup();
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  // moveToHomeOne($event: any) {
  //   this.router.navigate(['/']).then();
  // }

  moveToAddListing() {
    this.router.navigate(['/', 'dashboard-add-listings']).then();
  }

  initLoginFormGroup() {
    this.loginFormGroup = this.fb.group({
      email: [null, [Validators.required]],
      password: [null, [Validators.required]],
    });
  }

  initRegisterFormGroup() {
    this.registerFormGroup = this.fb.group({
      email: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      password: [null, [Validators.required]],
      lastName: [null],
    });
  }

  visibilityIconClick(ev: any, type: string) {
    ev.preventDefault();
    ev.stopPropagation();
    if (type == "password")
    {
        this.visibilityIcon = !this.visibilityIcon;
    }
  }

  async registeration() {
    if (this.registerFormGroup.valid && this.registerFormGroup.touched) {
      try {
        const registerFormRequest = this.registerFormGroup.getRawValue();
        const request: CreatUserRequest = {
          email: registerFormRequest.email,
          password: registerFormRequest.password,
          roleId: this.isBecomeAHost ? 2 : 3,
          lastName: registerFormRequest.lastName,
          firstName: registerFormRequest.firstName,
          fullName: `${registerFormRequest.firstName}-${registerFormRequest.lastName}`,
        };
        const register$ = this.accountApiService.createUser(request);
        const register = await lastValueFrom(register$, {
          defaultValue: undefined,
        });
        if (register) {
          this.loginFormGroup.controls['email'].patchValue(
            registerFormRequest.email
          );
          this.loginFormGroup.controls['password'].patchValue(
            registerFormRequest.password
          );
          this.isBecomeAHost = false;
          this.store.dispatch(new register(register));
          this.toastr.success(
            'Welcome' + `${request.fullName}`,
            'Registration Successfull',
            {
              timeOut: 3000,positionClass: 'toast-bottom-right',
            }
          );
          this.login();
        }
      } catch (e: any) {
        let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
        if (e?.error) {
          message = e.error.message ? e.error.message : e.error.errors[0];
          this.toastr.error(message, 'Please try again later', {
            timeOut: 3000,positionClass: 'toast-bottom-right',
          });
        }
      } finally {
        this.closeSigninSignupPopup();
      }
    }
  }

  openConsentScreen() {
    const obj = this.auth.loginWithRedirect();
  }

  async login() {
    if (this.loginFormGroup.touched && this.loginFormGroup.valid) {
      try {
        const loginFormRequest = this.loginFormGroup.getRawValue();
        const login$ = this.accountApiService.login(loginFormRequest);
        const login = await lastValueFrom(login$, {
          defaultValue: undefined,
        });
        if (login) {
          this.store.dispatch(new Login(login));
          const isAuthorizeSub = this.store
            .select(SnappstayState.Authorize)
            .subscribe((authorize) => {
              if (authorize) {
                this.closeSigninSignupPopup();
                this.userProfile();
                if (!authorize.roles.includes('Guest')) {
                  this.moveToDashboard();
                }
              }
            });
          this.subscriptions.push(isAuthorizeSub);
          this.toastr.success(
            'Welcome ' + `${loginFormRequest.email}`,
            'Login Successfull',
            {
              timeOut: 3000,positionClass: 'toast-bottom-right',
            }
          );
        }else{
          this.toastr.error('Invalid email or password!', 'Please try again', {
            timeOut: 3000,positionClass: 'toast-bottom-right',
          });
        }
      } catch (e: any) {
        let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
        if (e?.error) {
          message = e.error.message ? e.error.message : e.error.errors[0];
          this.toastr.error(message, 'Please try again later', {
            timeOut: 3000,positionClass: 'toast-bottom-right',
          });
        }

        this.store.dispatch(new Login(undefined));
      } finally {
      }
    }
  }
  async userProfile() {
    try {
      const getMyProfile$ = this.accountApiService.GetMyProfile();
      const getMyProfile = await lastValueFrom(getMyProfile$, {
        defaultValue: undefined,
      });
      if (getMyProfile) {
        this.store.dispatch(new SetUserProfile(getMyProfile));
      }
    } catch (e: any) {
      let message = 'SOMETHING_WENT_WRONG_TRY_AGAIN';
      if (e?.error) {
        message = e.error.message ? e.error.message : e.error.errors[0];
        this.toastr.error(message, 'Please try again later', {
          timeOut: 3000,positionClass: 'toast-bottom-right',
        });
      }
    } finally {
    }
  }

  // Navbar Sticky
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  alreadyHaveAccount() {
    this.isBecomeAHost = false;
    this.currentTab = 'tab1';
  }

  registerNow() {
    this.currentTab = 'tab2';
  }

  openSigninSignupPopup(): void {
    this.isSigninSignupOpen = true;
    this.currentTab = 'tab1';
  }

  becomeHostPopup(): void {
    this.isSigninSignupOpen = true;
    this.isBecomeAHost = true;
    this.currentTab = 'tab2';
  }
  closeSigninSignupPopup(): void {
    this.isBecomeAHost = false;
    this.isSigninSignupOpen = false;
    this.currentTab = 'tab1';

  }

  // Signin/Signup Tabs
  switchTab(event: MouseEvent, tab: string) {
    event.preventDefault();
    this.currentTab = tab;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
