import * as L from 'leaflet';
import { SnappstayBaseComponent } from 'src/app/components/base-component/base.component';
import { PopUpService } from '../popup.service';
import { MapMarker } from '../map-marker';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CityName } from 'src/app/models/lookup/city-names';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'snappstay-map-component',
  templateUrl: './map-component.component.html',
  styleUrls: ['./map-component.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ScrollingModule,
    NgSelectModule,
    FormsModule,
  ],
})
export class MapComponent
  extends SnappstayBaseComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() markersData: MapMarker[] = [];
  @Input() focus: { lat: number; lng: number }[] = [];
  @Input() isReadOnly: boolean = false;
  @Output() mapClick: EventEmitter<{ latitude: number; longitude: number }> =
    new EventEmitter();
  @Output() actionButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() searchOnZoom: EventEmitter<{
    bounds: L.LatLngBounds;
    zoomLevel: number;
  }> = new EventEmitter();
  map_views: any[] = [
    { id: 'google', name: 'Google Map' },
    { id: 'osm', name: 'OpenStreetMap' },
    { id: 'satellite', name: 'Satellite View' },
  ];
  selectedMapView: string = 'google';
  private map: L.Map | undefined;
  private markerLayer: L.LayerGroup<any> = new L.LayerGroup();
  private singleMarker: L.Marker | undefined;
  private googleTileLayer: L.TileLayer;
  private osmTileLayer: L.TileLayer;
  private satelliteTileLayer: L.TileLayer;
  private currentTileLayer: L.TileLayer;
  public currentView: string = 'Google Maps'; // Track current view
  public isStopBound: boolean = false;
  public filteredCities: CityName[] = [];
  constructor(inject: Injector, private popUpService: PopUpService) {
    super(inject);
  }

  ngOnInit(): void {
    this.filteredCities = this.cities;
  }

  onCitySearchChange(event: any): void {
    const value = event.target.value.toLowerCase();

    this.filteredCities = value
      ? this.cities.filter((city) => city.name.toLowerCase().includes(value))
      : this.cities;
  }
  displayCity(city: any): string {
    return city && city.name ? city.name : '';
  }

  onCitySelect(city: CityName): void {
    if (city) {
      this.map?.setView([city.latitude, city.longitude], 13);
      this.onZoomChange(); // Center map on selected city
    }
  }

  ngAfterViewInit(): void {
    this.initMap();
    if (this.markersData.length === 0 && this.isStopBound) {
      this.setCurrentLocation();
    }
    if (this.focus.length && !this.isStopBound) {
      this.fitBoundsToFocus();
    }

    // Add map click event listener
    this.map?.on('click', (e: L.LeafletMouseEvent) => {
      const switchContainer = document.querySelector('.switch-container');
      const switchBounds = switchContainer?.getBoundingClientRect();

      // Check if the click is outside the switch button container
      if (
        !this.isReadOnly &&
        (!switchBounds ||
          e.originalEvent.clientX < switchBounds.left ||
          e.originalEvent.clientX > switchBounds.right ||
          e.originalEvent.clientY < switchBounds.top ||
          e.originalEvent.clientY > switchBounds.bottom)
      ) {
        this.addMarkerOnClick(e.latlng);
      }

      // Always emit the clicked coordinates
      this.mapClick.emit({ latitude: e.latlng.lat, longitude: e.latlng.lng });
    });

    this.map?.on('zoomend', () => {
      if (this.isStopBound) {
        this.onZoomChange();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markersData && !changes.markersData.firstChange) {
      this.updateMarkers();
    }
    if (changes.focus && !changes.focus.firstChange && !this.isStopBound) {
      this.fitBoundsToFocus();
    }
  }

  private initMap(): void {
    this.map = L.map('map', {
      center: [37.7749, -122.4194],
      zoom: 13,
    });

    this.googleTileLayer = L.tileLayer(
      'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
      { attribution: '© Google' }
    );

    this.osmTileLayer = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }
    );

    // Satellite Tile Layer
    this.satelliteTileLayer = L.tileLayer(
      'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
      { attribution: '© Google Satellite' }
    );

    this.currentTileLayer = this.googleTileLayer;
    this.currentTileLayer.addTo(this.map);

    this.markerLayer.addTo(this.map);
    this.updateMarkers();
  }

  // Toggle between Google Maps, OpenStreetMap, and Satellite views
  switchMapView(event: any): void {
    this.map?.removeLayer(this.currentTileLayer);

    if (event.id === 'google') {
      this.currentTileLayer = this.googleTileLayer;
      this.currentView = 'Google Maps';
    } else if (event.id === 'osm') {
      this.currentTileLayer = this.osmTileLayer;
      this.currentView = 'OpenStreetMap';
    } else if (event.id === 'satellite') {
      this.currentTileLayer = this.satelliteTileLayer;
      this.currentView = 'Satellite View';
    } else if (!event) {
      this.currentTileLayer = this.googleTileLayer;
      this.currentView = 'Google Maps';
    }
    this.currentTileLayer.addTo(this.map!);
  }
  private filterCities(value: string) {
    const filterValue = value.toLowerCase();
    return this.cities.filter((city) =>
      city.name.toLowerCase().includes(filterValue)
    );
  }

  mapSearch(event: any): void {
    this.isStopBound = event.target.checked;
  }

  private updateMarkers(): void {
    this.markerLayer.clearLayers();
    this.markersData.forEach((marker) => {
      const leafletMarker = L.marker(
        [marker.latLng.latitude, marker.latLng.longitude],
        { icon: this.redIcon }
      );
      if (marker.name) {
        leafletMarker.bindPopup(this.popUpService.locationDetailPopup(marker));
      }
      leafletMarker.addTo(this.markerLayer);
    });
  }

  private addMarkerOnClick(latlng: L.LatLng): void {
    // Remove previous single marker if it exists
    this.markerLayer.clearLayers();
    if (this.singleMarker) {
      this.map?.removeLayer(this.singleMarker);
    }

    // Add new marker with custom red icon on map click
    this.singleMarker = L.marker([latlng.lat, latlng.lng], {
      icon: this.redIcon,
    }).addTo(this.map!);
  }

  private fitBoundsToFocus(): void {
    if (this.map && this.focus.length > 0) {
      const bounds = L.latLngBounds(
        this.focus.map((location) => [location.lat, location.lng])
      );
      this.map.fitBounds(bounds);
    }
  }

  private setCurrentLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.map?.setView([lat, lng], this.getZoomLevelForRadius(5000));
          this.singleMarker = L.marker([lat, lng], {
            icon: this.redIcon,
          }).addTo(this.map!);
        },
        (error) => console.error('Geolocation error:', error),
        { enableHighAccuracy: true }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }

  private getZoomLevelForRadius(radius: number): number {
    const EARTH_RADIUS = 6378137;
    const mapWidth = this.map?.getSize().x || 800;
    const metersPerPixel = (radius * 2) / mapWidth;
    const zoomLevel = Math.log2(
      (EARTH_RADIUS * Math.PI) / (metersPerPixel * 256)
    );
    return Math.round(zoomLevel);
  }

  private redIcon: L.Icon = L.icon({
    iconUrl: 'https://maps.gstatic.com/mapfiles/ms2/micons/red-dot.png', // Google-like red marker icon
    iconSize: [45, 45], // size of the icon
    iconAnchor: [16, 32], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -32], // point from which the popup should open relative to the iconAnchor
  });

  onZoomChange(): void {
    if (this.map) {
      const currentBounds = this.map.getBounds();
      const zoomLevel = this.map.getZoom();
      this.searchOnZoom.emit({ bounds: currentBounds, zoomLevel: zoomLevel });
    }
  }
  onActionButtonClick(): void {
    const customData = { action: true, timestamp: new Date() };
    this.actionButtonClick.emit(customData);
  }

  resetMap(): void {
    this.map.off();
    this.map.remove();
  }

  ngOnDestroy(): void {
    if (this.map) {
      this.resetMap();
    }
  }
}
