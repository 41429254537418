import { Component, Injector, OnInit } from '@angular/core';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { HomeoneBannerComponent } from './homeone-banner/homeone-banner.component';
import { FeaturesComponent } from '../../common/features/features.component';
import { HomeoneListingsComponent } from './homeone-listings/homeone-listings.component';
import { CategoryComponent } from '../../common/category/category.component';
import { HomeoneDestinationsComponent } from './homeone-destinations/homeone-destinations.component';
import { FeedbackStyleOneComponent } from '../../common/feedback-style-one/feedback-style-one.component';
import { HowItWorksComponent } from '../../common/how-it-works/how-it-works.component';
import { HomeoneBlogComponent } from './homeone-blog/homeone-blog.component';
import { AppDownloadComponent } from '../../common/app-download/app-download.component';
import { FooterStyleOneComponent } from '../../common/footer-style-one/footer-style-one.component';
import { CommonModule } from '@angular/common';
import { SnappstayBaseComponent } from '../../base-component/base.component';

@Component({
  selector: 'app-home-demo-one',
  templateUrl: './home-demo-one.component.html',
  styleUrls: ['./home-demo-one.component.scss'],
  imports:[
    NavbarStyleOneComponent,
    HomeoneBannerComponent,
    FeaturesComponent,
    HomeoneListingsComponent,
    CategoryComponent,
    HomeoneDestinationsComponent,
    FeedbackStyleOneComponent,
    HowItWorksComponent,
    HomeoneBlogComponent,
    AppDownloadComponent,
    FooterStyleOneComponent,
    CommonModule
  ],
  standalone: true
})
export class HomeDemoOneComponent extends SnappstayBaseComponent implements OnInit {

  constructor(
    inject: Injector,
  ) {
    super(inject);
  }

  ngOnInit(): void {
  }

}
