import { Component, OnInit } from '@angular/core';
import { FooterStyleTwoComponent } from '../../common/footer-style-two/footer-style-two.component';
import { HometwoDestinationsComponent } from '../home-demo-two/hometwo-destinations/hometwo-destinations.component';
import { NavbarStyleOneComponent } from '../../common/navbar-style-one/navbar-style-one.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-top-place',
    templateUrl: './top-place.component.html',
    styleUrls: ['./top-place.component.scss'],
    standalone: true,
    imports:[
        FooterStyleTwoComponent,
        HometwoDestinationsComponent,
        NavbarStyleOneComponent,
        CommonModule
    ]
})
export class TopPlaceComponent implements OnInit {

        

    ngOnInit(): void {
    }
    
    pageTitleContent = [
        {
            title: 'Destinations',
            backgroundImage: 'assets/img/page-title/page-title5.jpg'
        }
    ]

}